<template>
    <el-dialog
        v-model="show"
        title="工单轨迹"
        width="1000px"
    >
        <div class="task-work-order-locus">
            <div id="map" class="map"></div>
           <div class="button-list">
               <el-button @click="marker.moveAlong(lineArr, 2000)">开始动画</el-button>
               <el-button @click="marker.pauseMove()">暂停动画</el-button>
               <el-button @click="marker.resumeMove()">继续动画</el-button>
               <el-button @click="marker.stopMove()">停止动画</el-button>
           </div>
        </div>
    </el-dialog>
</template>

<script>
import {reactive, toRefs, watch} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import {GET_LOCATIONS} from "../../../api/dataProvider";

export default {
    name: "TaskWorkOrderLocus",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["update:show"],
    setup(props, context) {
        let state = reactive({
            marker: [],
            lineArr: [],
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
            if (val) {
                getLocation()
            }
        })

        let getLocation = () => {
            GET_LOCATIONS(
                {
                    ticketId: props.id,
                    size: -1
                },
                (res) => {
                    console.log(res.data)
                    if (res.data.data && res.data.data.length > 0) {
                        let locusArr = []

                        res.data.data.map((item) => {
                            locusArr.push([item.longitude, item.latitude])
                        })

                        state.marker = locusArr
                        state.lineArr = locusArr

                        AMapLoader.load({
                            "key": "7023740654d6ddc40d0332d261beb213",   // 申请好的Web端开发者Key，首次调用 load 时必填
                            "version": "1.4.15",                         // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                            "plugins": [],                               // 需要使用的的插件列表，如比例尺"AMap.Scale"等
                            "AMapUI": {                                  // 是否加载 AMapUI，缺省不加载
                                "version": "1.1",                        // AMapUI 缺省 1.1
                                "plugins": [],                           // 需要加载的 AMapUI ui插件
                            },
                            "Loca": {                                    // 是否加载 Loca， 缺省不加载
                                "version": "1.3.2"                       // Loca 版本，缺省 1.3.2
                            },
                        }).then((AMap) => {
                            let map = new AMap.Map("map", {
                                resizeEnable: true,
                                center: [res.data.data[0].longitude, res.data.data[0].latitude],
                                zoom: 17
                            });

                            state.marker = new AMap.Marker({
                                map: map,
                                position: [res.data.data[0].longitude, res.data.data[0].latitude],
                                icon: "https://webapi.amap.com/images/car.png",
                                offset: new AMap.Pixel(-26, -13),
                                autoRotation: true,
                                angle: -90,
                            });

                            // 绘制轨迹
                            let polyline = new AMap.Polyline({
                                map: map,
                                path: state.lineArr,
                                showDir: true,
                                strokeColor: "#28F",  //线颜色
                                strokeWeight: 6,      //线宽
                            });

                            let passedPolyline = new AMap.Polyline({
                                map: map,
                                strokeColor: "#AF5",  //线颜色
                                strokeWeight: 6,      //线宽
                            });


                            state.marker.on("moving", function (e) {
                                passedPolyline.setPath(e.passedPath);
                            });

                            map.setFitView();
                        })
                    }
                }
            )
        }

        return {
            ...toRefs(state),
            getLocation
        }
    },
}
</script>

<style lang="scss" scoped>
.task-work-order-locus {
    .map {
        height: 600px;
    }
    .button-list{
        margin-top: 20px;
    }
}
</style>