<template>
    <div class="task-work-order-add">
        <el-dialog
            v-model="show"
            title="新增工单"
            width="80%"
        >
            <div class="task-work-order-detail">
                <el-tabs>
                    <el-tab-pane label="基本信息">
                        <div class="basic-info">
                            <el-form :model="taskDetail" label-position="left" label-width="100px">
                                <el-row :gutter="50">
                                    <el-col :span="8">
                                        <el-form-item label="工单编号：">
                                            <el-input v-model="taskDetail.number" placeholder="请输入工单编号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="服务类型：">
                                            <el-select v-model="taskDetail.serviceType" placeholder="请选择服务类型">
                                                <template
                                                    v-for="(item,index) in $store.getters.geetServiceTypeList"
                                                    :key="index"
                                                >
                                                    <el-option
                                                        v-if="item.platform==='CPIC'"
                                                        :label="item.label"
                                                        :value="item.value"
                                                    ></el-option>
                                                </template>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="保单号：">
                                            <el-input v-model="taskDetail.policyNumber" placeholder="请输入保单号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车牌号码：">
                                            <el-input v-model="taskDetail.plateNumber" placeholder="请输入车牌号码"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车架号：">
                                            <el-input v-model="taskDetail.vin" placeholder="请输入车架号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车主姓名：">
                                            <el-input v-model="taskDetail.name" placeholder="请输入车主姓名"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车主电话：">
                                            <el-input v-model="taskDetail.phone" placeholder="请输入车主电话"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="承保公司：">
                                            <el-input v-model="taskDetail.platform" disabled
                                                      placeholder="请输入承保公司"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="是否事故：">
                                            <el-select v-model="taskDetail.isAccident" placeholder="请选择是否事故">
                                                <el-option :value="false" label="非事故"></el-option>
                                                <el-option :value="true" label="事故"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="案件地址：">
                                            <el-input
                                                v-model="taskDetail.location"
                                                placeholder="请输入案件地址"
                                                @blur="searchInMap(taskDetail.location)"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="送达目的地：">
                                            <el-input
                                                v-model="taskDetail.destination"
                                                placeholder="请输入送达目的地"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="工单类别：">
                                            <el-select v-model="taskDetail.type" placeholder="请选择工单类别">
                                                <el-option label="服务受理" value="ACCEPT"></el-option>
                                                <el-option label="服务催促" value="URGE"></el-option>
                                                <el-option label="投诉" value="COMPLAIN"></el-option>
                                                <el-option label="补充" value="SUPPLY"></el-option>
                                                <el-option label="报销" value="PAY"></el-option>
                                                <el-option label="取消" value="CANCEL"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="工单状态：">
                                            <el-select v-model="taskDetail.status" disabled placeholder="请选择工单状态">
                                                <el-option
                                                    v-for="(item,index) in $store.state.task.statusList"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="报案时间：">
                                            <el-date-picker
                                                v-model="taskDetail.reportedAt"
                                                placeholder="选择日期时间"
                                                type="datetime"
                                                @change="changeReportedAt"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <div id="container" class="form-map">
                                        </div>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="备注：">
                                            <el-input
                                                v-model="taskDetail.comment"
                                                :rows="4"
                                                placeholder="请输入备注"
                                                type="textarea"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <template #footer>
                <el-button @click="show = false">取消</el-button>
                <el-button type="primary" @click="submitForm()">新增</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import {ElMessage} from "element-plus";
import {ADD_TICKETS} from "../../../api/dataProvider";
import useFormatDateTime from "../../../hooks/useFormatDateTime.js"

export default {
    name: "TaskWorkOrderAdd",
    props: {
        show: Boolean,
    },
    emits: ["update:show", "updateList"],
    setup(props, context) {
        let state = reactive({
            taskDetail: {
                platform: "CPIC",
                status: "READ"
            },
        })

        onMounted(() => {
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })


        // 高德地图搜索
        let searchInMap = (val) => {
            AMapLoader.load({
                "key": "7023740654d6ddc40d0332d261beb213",   // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "1.4.15",                         // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": [],                               // 需要使用的的插件列表，如比例尺"AMap.Scale"等
                "AMapUI": {                                  // 是否加载 AMapUI，缺省不加载
                    "version": "1.1",                        // AMapUI 缺省 1.1
                    "plugins": [],                           // 需要加载的 AMapUI ui插件
                },
                "Loca": {                                    // 是否加载 Loca， 缺省不加载
                    "version": "1.3.2"                       // Loca 版本，缺省 1.3.2
                },
            }).then((AMap) => {
                AMap.Map("container", {});
                AMap.plugin("AMap.Geocoder", () => {
                    var geocoder = new AMap.Geocoder({})
                    geocoder.getLocation(val, (status, result) => {
                        if (status === "complete" && result.info === "OK") {
                            searchInMapSelect(result.geocodes[0])
                            ElMessage({
                                message: "地图加载成功",
                                type: "success"
                            });
                        } else {
                            ElMessage({
                                message: "地图加载失败，请重试！",
                                type: "error"
                            });
                        }
                    })
                })
            }).catch(e => {
            })
        }

        // 高德地图选中地址
        let searchInMapSelect = (item) => {
            state.taskDetail.latitude = item.location.lat
            state.taskDetail.longitude = item.location.lng
            let map = new AMap.Map("container", {
                zoom: 15,
                center: [item.location.lng, item.location.lat]
            });
            let marker = new AMap.Marker("container", {
                position: new AMap.LngLat(item.location.lng, item.location.lat),
                title: item.name,
            });
            map.add(marker);
        }

        let {formatDateTime} = useFormatDateTime()

        // 更改报案时间
        let changeReportedAt = (val) => {
            state.taskDetail.reportedAt = formatDateTime(val)
        }

        // 提交数据
        let submitForm = () => {
            ADD_TICKETS(
                {
                    ...state.taskDetail
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "新增成功!",
                            type: "success",
                        });
                        updateList()
                    } else {
                        ElMessage({
                            message: "新增失败，请检查后重试！",
                            type: "error"
                        });
                    }
                }
            )
            console.log(111111, state.taskDetail)
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }


        return {
            ...toRefs(state),
            searchInMap,
            searchInMapSelect,
            formatDateTime,
            changeReportedAt,
            submitForm
        }
    },
}
</script>

<style lang="scss" scoped>
.task-work-order-add {
    ::v-deep(.el-form) {
        .el-form-item {
            .el-select {
                width: 100%;
            }

            .el-input {
                width: 100%;
            }

            .el-autocomplete {
                width: 100%;
            }
        }
    }

    .basic-info {
        padding-top: 15px;

        .form-map {
            width: 100%;
            height: 500px;
            margin-bottom: 22px;
        }
    }
}
</style>